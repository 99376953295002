import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo_transparente from '../img/logo_transparente.png';

const Footer = () => {
  return (
    <Container fluid className="bg-light text-dark">
      <Row className="align-items-center">
        <Col md={4} className="d-flex justify-content-center justify-content-md-start">
          <img src={logo_transparente} alt="logo de VetZoo" className="logo-footer" />
        </Col>
        <Col md={8} className="d-flex justify-content-center justify-content-md-end">
          <p className="mr-md-5 mb-0 p-2">Dra. Déborah Bunster</p>
          <p className="mr-md-5 mb-0 p-2">Teléfono: +56 9 7612 8706</p>
          <p className="mb-0 p-2">Correo electrónico: consultavetzoo@gmail.com</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
