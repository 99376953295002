import React from "react";
import "./App.css";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

import Slider from "../src/components/Slider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>VetZoo - Dra. Déborah Bunster</title>
        <meta
          name="Clínica Veterinaria Animales Exóticos - Santiago, Chile"
          content="Veterinaria de hurones, cuyis, conejos, chinchillas, cobayas, erizos, peces, reptiles, aves, etc."
        />
        <meta name="keywords" content="huron, hurones, hurones chile, veterinario hurones, cuy, cuyi, erizo, loro" />
      </Helmet>

      <Container fluid className="header-container">
        <Header />
      </Container>

      <Container fluid className="hero-container">
        <Hero />
      </Container>

      <Container fluid className="slider-container">
        <Slider />
      </Container>

      <Container fluid className="footer-container">
        <Footer />
      </Container>
    </div>
  );
}

export default App;
