import React from 'react'
import logo_transparente from '../img/logo_transparente.png'

const Header = () => {
  return (
    
    <div className="header-content">
      <img
        src={logo_transparente}
        alt="logo de VetZoo"
        className="logo-header"
      />
    </div>
 
  )
}

export default Header