import React from 'react';
import { Carousel } from 'react-bootstrap';

import Slider1 from '../img/slider/Slider1.png';
import Slider2 from '../img/slider/Slider2.png';
import Slider3 from '../img/slider/Slider3.png';
import Slider4 from '../img/slider/Slider4.png';
import Slider5 from '../img/slider/Slider5.png';
import Slider6 from '../img/slider/Slider6.png';
import Slider7 from '../img/slider/Slider7.png';
import Slider8 from '../img/slider/Slider8.png';
import Slider9 from '../img/slider/Slider9.png';
import Slider10 from '../img/slider/Slider10.png';
import Slider11 from '../img/slider/Slider11.png';
import Slider12 from '../img/slider/Slider12.png';


function Slider() {
    return (
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider1}
            alt="Slider1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider2}
            alt="Slider2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider3}
            alt="Slider3"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider4}
            alt="Slider4"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider5}
            alt="Slider5"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider6}
            alt="Slider6"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider7}
            alt="Slider7"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider8}
            alt="Slider8"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider9}
            alt="Slider9"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider10}
            alt="Slider10"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider11}
            alt="Slider11"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-auto mx-auto my-auto img-slider"
            src={Slider12}
            alt="Slider12"
          />
        </Carousel.Item>
      </Carousel>
    );
    }

export default Slider;